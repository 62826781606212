import React from "react";
import { StaticImage } from 'gatsby-plugin-image'
import { makeStyles } from "@material-ui/core/styles";

// kit
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import Brown from "components/Typography/Brown.js";

// image
import titleImage from "../../images/tit1.png";

import shingenStyle from "style/kit_shingenStyle.js";
const useStyles = makeStyles(shingenStyle);

export default function SectionText() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer>
        <GridItem xs={12} >
          <div className={classes.sectionTitle}>
            <Brown>
              <img src={titleImage} alt="" />
              <h2>
                <span>借りる</span>
              </h2>
            </Brown>
            <div>
              <p>賃貸住宅・月極駐車場をお探しのお客様はぜひ、申源へお任せください。</p>
            </div>
          </div>
        </GridItem>
        <GridItem xs={12} className={classes.textCenter}>
          <Button
            variant="contained"
            color="brown"
            href="/chintai_p1/"
            className={classes.card_button}
          >
            <StaticImage
              src="../../images/p2_b1-1.png"
              layout="fullWidth"
              placeholder="blurred"
              alt="賃貸：家賃3.5万円以下"
            /> 
          </Button>
          <Button
            variant="contained"
            color="brown"
            href="/chintai_p2/"
            className={classes.card_button}
          >
            <StaticImage
              src="../../images/p2_b2-1.png"
              layout="fullWidth"
              placeholder="blurred"
              alt="賃貸：ペットOKの部屋"
            /> 
          </Button>
          <Button
            variant="contained"
            color="brown"
            href="/chintai_p3/"
            className={classes.card_button}
          >
            <StaticImage
              src="../../images/p2_b3-1.png"
              layout="fullWidth"
              placeholder="blurred"
              alt="賃貸：戸建て賃貸"
            /> 
          </Button>
          <Button
            variant="contained"
            color="brown"
            href="/chintai_p4/"
            className={classes.card_button}
          >
            <StaticImage
              src="../../images/p2_b4-1.png"
              layout="fullWidth"
              placeholder="blurred"
              alt="賃貸：技科大周辺"
            /> 
          </Button> 
          <Button
            variant="contained"
            color="brown"
            href="/chintai_p5/"
            className={classes.card_button}
          >
            <StaticImage
              src="../../images/p2_b5-1.png"
              layout="fullWidth"
              placeholder="blurred"
              alt="賃貸：豊橋駅まで徒歩10分圏内"
            /> 
          </Button>
          <Button
            variant="contained"
            color="brown"
            href="/parking/"
            className={classes.card_button}
          >
            <StaticImage
              src="../../images/p2_b10-1.png"
              layout="fullWidth"
              placeholder="blurred"
              alt="賃貸：月極駐車場"
            /> 
          </Button>
        </GridItem>
      </GridContainer>
    </div>
  );
}
