import React from "react";
import { StaticImage } from 'gatsby-plugin-image'
import { makeStyles } from "@material-ui/core/styles";

// kit
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import Brown from "components/Typography/Brown.js";

// image
import titleImage from "../../images/tit2.png";

import shingenStyle from "style/kit_shingenStyle.js";
const useStyles = makeStyles(shingenStyle);

export default function SectionText() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer>
        <GridItem xs={12}>
          <div className={classes.sectionTitle}>
            <Brown>
              <img src={titleImage} alt="" />
              <h2>
                <span>買う</span>
              </h2>
            </Brown>
            <div>
              <p>
                土地や住宅の購入や売却も承ります。
              </p>
            </div>
          </div>
        </GridItem>
        <GridItem xs={12} className={classes.textCenter}>
          <Button
            variant="contained"
            color="brown"
            href="/hanbai_p1/"
            className={classes.card_button}
          >
            <StaticImage
              src="../../images/p2_b7-1.png"
              layout="fullWidth"
              placeholder="blurred"
              alt="販売：厳選住宅情報"
            /> 
          </Button>
          <Button
            variant="contained"
            color="brown"
            href="/hanbai_p2/"
            className={classes.card_button}
          >
            <StaticImage
              src="../../images/p2_b11-1.png"
              layout="fullWidth"
              placeholder="blurred"
              alt="販売：売土地情報"
            /> 
          </Button>
          <Button
            variant="contained"
            color="brown"
            href="/hanbai_p3/"
            className={classes.card_button}
          >
            <StaticImage
              src="../../images/p2_b9-1.png"
              layout="fullWidth"
              placeholder="blurred"
              alt="販売：店舗兼用住宅"
            /> 
          </Button>
        </GridItem>
      </GridContainer>
    </div>
  );
}
