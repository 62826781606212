/*eslint-disable*/ 
import React from "react";
import Layout from 'components/layout'
import Seo from 'components/seo'
import { StaticImage } from 'gatsby-plugin-image'
import { makeStyles } from "@material-ui/core/styles";


// core components
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Success from "components/Typography/Success.js";

import SectionKariru from "views/Shingen/SectionKariru.js";
import SectionKau from "views/Shingen/SectionKau.js";
import SectionSodan from "views/Shingen/SectionSodan.js";

import shingenStyle from "style/kit_shingenStyle.js";
const useStyles = makeStyles(shingenStyle);
// img
import logo_l from "images/logo_l.svg"

export default function Index() {
  /*React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });*/
  const classes = useStyles();
  return (
    <Layout active="1">
      <Seo
        title="豊橋駅前の不動産屋"
        description="豊橋市にある不動産屋です。豊橋市、豊川市の土地売買、賃貸はしんげん"
      />
    <div>
      <Parallax image={require("images/header_back6.jpg").default} filter="dark" 
        className={classes.parallax}
      >
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem sm={10} md={12} className={classes.textCenter} >
              <h1 className={classes.title} id="logo_l">
                <img src={logo_l} alert="有限会社 申源"/>
              </h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classes.main}>
        <div className={classes.container}>
          <div className={classes.index_top}>
            <div>
              <Success>
                <h4>
                  豊橋駅前の不動産屋です。<br />
                  豊橋・豊川の土地・建物のことなら、申源へお任せください。
                </h4>
              </Success>
            </div>
            <div>
              <StaticImage
                src="../images/p1_1.svg"
                layout="fullWidth"
                placeholder="tracedSVG"
                alt=""
                className={classes.p1_1}
              />
              <div className={classes.p1_1_text + " " + classes.hashi}>豊橋</div>
              <div className={classes.p1_1_text + " " + classes.kawa}>豊川</div>
            </div>
          </div>
          <SectionKariru />
          <SectionKau />
          <SectionSodan />
        </div>
      </div>
    </div>
  </Layout>
  );
}
