import React from "react";
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby-theme-material-ui'

import { makeStyles } from "@material-ui/core/styles";
// kit
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import Primary from "components/Typography/Primary.js";
import Brown from "components/Typography/Brown.js";

// @material-ui/icons
import Call from "@material-ui/icons/Call";
import Mail from "@material-ui/icons/Mail";
// image
import titleImage from "../../images/tit3.png";
import line_icon from "../../images/line_logo.svg";

import SectionSodan2 from "views/Shingen/SectionSodan2.js";
import SectionSodan3 from "views/Shingen/SectionSodan3.js";

import shingenStyle from "style/kit_shingenStyle.js";
const useStyles = makeStyles(shingenStyle);
const Styles = makeStyles((theme) => ({
  sec_title: {
    paddingRight: '0',
  },
  toiawase_area:{
    [theme.breakpoints.down('lg')]: {
      '& > div':{ marginBottom: '0', }
    },
  },
  w100:{
    width: '100%',
  },
  calenderbox:{
    maxWidth: '240px',
    minWidth: '240px',
    marginLeft: '2em',
    padding: '.5em 1em',
    display: 'inline-block',
    border: 'solid 1px',
    '& h6':{
      margin: '0',
      textAlgen: 'center',
    },
  },
}))
export default function SectionText() {
  const classes = useStyles();
  const cl = Styles();
  return (
    <div className={classes.section}>
      <GridContainer justifyContent="center">
        <GridItem xs={12} className={ cl.sec_title }>
          <div className={classes.sectionTitle}>
            <Brown>
              <img src={titleImage} alt="" />
              <h2>
                <span>相談する</span>
              </h2>
            </Brown>
            <div>
              <p>
                どんなことでもお気軽にご相談ください。<br />
              </p>
              <div  className={classes.sodan2_box}>
                <div>
                  <span className={classes.sodan_add}>
                    <span>
                      有限会社 申源
                    </span>
                    <span>
                      愛知県豊橋市駅前大通3丁目83<br />
                      営業時間：月曜～金曜 9:00～17:30<br/>
                      4月～11月は第2土曜 9:00～17:30　<span>12月～3月は第2.4土曜 9:00～17:30</span><br/>
                      (土曜不定休、日曜定休、<span>GW・お盆・年末年始休業あり</span>)<br/>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </GridItem>
      </GridContainer>
      <SectionSodan2 />
    </div>
  );
}
